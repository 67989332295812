import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TotalKas from '../components/TotalKas';
import CeramahJumat from '../components/CeramahJumat';
import Header from '../components/Monitor/Header';
import Chart from '../components/Monitor/Chart';
import InfoMajelis from '../components/Monitor/InfoMajelis';
import DaftarInfaq from '../components/Monitor/DaftarInfaq';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        maxWidth: 'none'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        borderRadius: '13px',
        background: 'linear-gradient(to right, rgb(59, 138, 87), #3eb554)',
    },
    fixedHeight: {
        height: 280,
        maxWidth: 380
    },
    ceramahJumat: {
        height: 280,
        maxWidth: 380
    }
}));

export default function Dashboard() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedHeighPaperCeramahJumat = clsx(classes.paper, classes.ceramahJumat)

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} style={{ minHeight: '48px' }} />
                <Container className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Header />
                        </Grid>
                        <Grid item xs={12} md={9} lg={10}>
                            <Chart />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                            <Paper className={fixedHeightPaper}>
                                <TotalKas />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <InfoMajelis />
                        </Grid>
                        <Grid item xs={12} md={5} lg={6}>
                            <DaftarInfaq />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                            <Paper className={fixedHeighPaperCeramahJumat}>
                                <CeramahJumat />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}