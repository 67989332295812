import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table'
import axios from 'axios';
import Format from '../../utils/Format';
import SuccessSnackbar from '../../components/Snackbar/Success';
import FailedSnackbar from '../../components/Snackbar/Failed';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        margin: theme.spacing(1),
    },
    button: {
        textAlign: 'right',
    },
    bold: {
        fontWeight: 'bold'
    },
    card: {
        marginTop: theme.spacing(7),
        borderRadius: theme.spacing(0.5),
        transition: '0.3s',
        width: '100%',
        display: 'grid',
        overflow: 'initial',
        background: '#ffffff',
        minHeight: 600
    },
    content: {
        textAlign: 'left',
        overflowX: 'auto',
    },
    header: {
        background: 'linear-gradient(to right, rgb(59, 138, 87), #3eb554)',
    }
}));

function EditPenceramah({ ...props }) {
    const { isOpen, isClose, detailData, fetchData } = props;
    const [body, setBody] = useState({});
    const [selectedDate, setSelectedDate] = useState();
    const [tanggal, setSelectedTanggal] = useState();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setSelectedDate(detailData.tanggal)
        setSelectedTanggal(Format.FullDate(detailData.tanggal))
        setBody({
            hari: detailData.hari,
            total: detailData.total,
            update_by: 'Admin',
            deskripsi: detailData.deskripsi
        })
    }, [detailData, selectedDate])

    const handleChangeTotal = event => {
        setBody({
            ...body,
            total: event.target.value
        })
    }
    const handleChangeDeskripsi = event => {
        setBody({
            ...body,
            deskripsi: event.target.value
        })
    }
    const handleChangeTanggal = event => {
        setSelectedTanggal(Format.FullDate(event))
    }

    const handleEditData = event => {
        event.preventDefault();
        axios.put(`https://lit-sierra-81196.herokuapp.com/api/pemasukan/${detailData.id}`, { ...body, tanggal })
            .then(res => {
                setSuccess(true)
                fetchData()
                isClose()
            })
            .catch(err => {
                setError(true)
                isClose()
            })
    }

    return (
        <div>
            <Dialog open={isOpen} onClose={isClose}>
                <DialogTitle id="form-dialog-title">Edit Detail Penceramah</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Hari"
                                value={body.hari ? body.hari : ''}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                clearable
                                required
                                value={selectedDate}
                                inputVariant="outlined"
                                label="Tanggal"
                                onChange={date => handleChangeTanggal(date)}
                                minDate={new Date()}
                                format="MM/dd/yyyy"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CurrencyTextField
                                label="Total"
                                variant="outlined"
                                currencySymbol="Rp"
                                value={body.total ? parseFloat(body.total.replace(/,/g, '')) : ''}
                                //minimumValue="0"
                                outputFormat="string"
                                decimalCharacter="."
                                digitGroupSeparator=","
                                onChange={handleChangeTotal}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Deskripsi"
                                variant="outlined"
                                fullWidth
                                value={body.deskripsi ? body.deskripsi : ''}
                                onChange={handleChangeDeskripsi}
                            />
                        </Grid>
                        {
                            success ?
                                <SuccessSnackbar /> :
                                error ?
                                    <FailedSnackbar /> : <div />
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={isClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditData} color="primary">
                        Edit
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default function CenteredGrid() {
    const classes = useStyles();
    const cardHeaderStyles = useContainedCardHeaderStyles();
    const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
    const cardHeaderShadowStyles = useFadedShadowStyles();
    const [data, setData] = useState();
    const [detailData, setDetailData] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const columns = [
        { title: 'Hari', field: 'hari' },
        { title: 'Tanggal', field: 'tanggal' },
        { title: 'Total', field: 'total' },
        { title: 'Deskripsi', field: 'deskripsi' }
    ]
    const [showEditDialog, setShowEditDialog] = useState(false)

    useEffect(() => {
        let newData;
        axios.get('https://lit-sierra-81196.herokuapp.com/api/pemasukan')
            .then(res => {
                newData = res ? res.data.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal) })) : []
                setData(newData)
            })
            .catch(err => {
                setData([])
            })
    }, [])

    const handleShowEditDialog = (rowData) => {
        setDetailData(rowData)
        setShowEditDialog(true)
    }

    const handleCloseEditDialog = () => {
        setShowEditDialog(false)
    }

    const handleDeleteData = (id) => {
        axios.delete(`https://lit-sierra-81196.herokuapp.com/api/pemasukan/${id}`)
            .then(res => {
                setSuccess(true)
                fetchDataAfterSubmit()
            })
            .catch(err => {
                setError(true)
            })
    }

    const fetchDataAfterSubmit = () => {
        let newData;
        axios.get('https://lit-sierra-81196.herokuapp.com/api/pemasukan')
            .then(res => {
                newData = res ? res.data.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal) })) : []
                setData(newData)
            })
            .catch(err => {
                setData([])
            })
    }

    return (
        <div className={classes.root}>
            <EditPenceramah isOpen={showEditDialog} isClose={handleCloseEditDialog} detailData={detailData} fetchData={fetchDataAfterSubmit} />
            <Card className={cx(classes.card, cardShadowStyles.root)}>
                <CardHeader
                    className={cx(cardHeaderShadowStyles.root, classes.header)}
                    classes={cardHeaderStyles}
                    title={'Tabel Pemasukan'}
                    subheader={'List Detail Pemasukan.'}
                />
                <CardContent className={classes.content}>
                    <Grid container spacing={3} style={{ marginTop: 8 }}>
                        <Grid item xs={12}>
                            <MaterialTable
                                style={{ boxShadow: 'none' }}
                                columns={columns}
                                data={data}
                                actions={[
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit Data',
                                        onClick: (event, rowData) => handleShowEditDialog(rowData)
                                    },
                                    {
                                        icon: 'delete',
                                        tooltip: 'Hapus Data',
                                        onClick: (event, rowData) => handleDeleteData(rowData.id)
                                    }
                                ]}
                                options={{
                                    search: false,
                                    toolbar: false,
                                    pageSize: 10,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: '#3eb554',
                                        color: '#FFF',
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        {
                            success ?
                                <SuccessSnackbar /> :
                                error ?
                                    <FailedSnackbar /> : <div />
                        }
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}