import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Column } from '@mui-treasury/components/flex';
import { Info } from '@mui-treasury/components/info';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise'
import Format from '../../utils/Format'

const useStyles = makeStyles(() => ({
    card: {
        position: 'relative',
        borderRadius: 16,
        padding: 12,
        backgroundColor: '#107a8b',
        minWidth: 400,
        marginBottom: 30
    },
    img: {
        position: 'absolute',
        width: '18%',
        top: 0,
        bottom: 0,
        right: 0,
        display: 'block',
    },
    shell: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        transform: 'translate(70%, 50%)',
        borderRadius: '50%',
        backgroundColor: 'rgba(71, 167, 162, 0.12)',
        padding: '40%',
        '&:before': {
            position: 'absolute',
            borderRadius: '50%',
            content: '""',
            display: 'block',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: '-16%',
            backgroundColor: 'rgba(71, 167, 162, 0.08)',
        }
    },
}));

const OfferCardDemo = React.memo(function OfferCard() {
    const styles = useStyles();
    const shadowStyles = useSoftRiseShadowStyles();
    return (
        <>
            <Card className={cx(styles.card, shadowStyles.root)}>
                <Column gap={2} mr={2}>
                    <Info position={'middle'} style={{ color: '#fff' }}>
                        <Typography component="p" variant="h6" gutterBottom>
                            SELAMAT DATANG,
                        </Typography>
                        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                            MESJID NURUL IMAN, MAKASSAR.
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <Typography color="textSecondary" variant="overline" style={{ color: '#fff', fontWeight: 'bold' }}>
                                {Format.TodayInWeek()}
                            </Typography>
                            <Typography color="textSecondary" variant="overline" style={{ color: '#fff' }}>
                                / {Format.DateOnly(new Date())}
                            </Typography>
                        </div>
                    </Info>
                </Column>
                <img
                    className={styles.img}
                    alt={''}
                    src={require("../../assets/img/salam.png")}
                />
                <div className={styles.shell} />
            </Card>
        </>
    );
});

export default OfferCardDemo