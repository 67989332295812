import React, { useState } from 'react';
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Format from '../../utils/Format';
import axios from 'axios';
import SuccessSnackbar from '../../components/Snackbar/Success';
import FailedSnackbar from '../../components/Snackbar/Failed';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        margin: theme.spacing(1),
    },
    button: {
        textAlign: 'right',
    },
    bold: {
        fontWeight: 'bold'
    },
    card: {
        marginTop: theme.spacing(7),
        borderRadius: theme.spacing(0.5),
        transition: '0.3s',
        width: '100%',
        display: 'grid',
        overflow: 'initial',
        background: '#ffffff',
        minHeight: 300
    },
    content: {
        textAlign: 'left',
        overflowX: 'auto',
    },
    header: {
        background: 'linear-gradient(to right, rgb(59, 138, 87), #3eb554)',
    },
    formControl: {
        width: '100%'
    },
}));

export default function CenteredGrid() {
    const classes = useStyles();
    const cardHeaderStyles = useContainedCardHeaderStyles();
    const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
    const cardHeaderShadowStyles = useFadedShadowStyles();
    const [tanggal, setSelectedTanggal] = useState(Format.FullDate(new Date()));
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [body, setBody] = useState({
        total: '',
        update_by: 'Admin',
        deskripsi: ''
    })
    const [hari, setHari] = useState('')
    const [status, setStatus] = useState('')

    const handleChangeHari = event => {
        setHari(event.target.value)
    }
    const handleChangeTotal = event => {
        setBody({
            ...body,
            total: event.target.value
        })
    }
    const handleChangeDeskripsi = event => {
        setBody({
            ...body,
            deskripsi: event.target.value
        })
    }
    const handleChangeStatus = event => {
        setStatus(event.target.value)
    }
    const handleChangeTanggal = event => {
        setSelectedTanggal(Format.FullDate(event))
    }

    const handleButtonSubmit = event => {
        event.preventDefault();
        axios.post('https://lit-sierra-81196.herokuapp.com/api/pengeluaran', { ...body, status, hari, tanggal })
            .then(res => {
                setSuccess(true)
                window.location.reload();
            })
            .catch(err => {
                setError(true)
            })
    }

    return (
        <div className={classes.root}>
            <Card className={cx(classes.card, cardShadowStyles.root)}>
                <CardHeader
                    className={cx(cardHeaderShadowStyles.root, classes.header)}
                    classes={cardHeaderStyles}
                    title={'Input Pengeluaran'}
                    subheader={'Detail Pengeluaran.'}
                />
                <CardContent className={classes.content}>
                    <Grid container spacing={3} style={{ marginTop: 8 }}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" required className={classes.formControl}>
                                <InputLabel>Hari</InputLabel>
                                <Select
                                    value={hari}
                                    onChange={handleChangeHari}
                                >
                                    <MenuItem value={"Ahad"}>Ahad</MenuItem>
                                    <MenuItem value={"Senin"}>Senin</MenuItem>
                                    <MenuItem value={"Selasa"}>Selasa</MenuItem>
                                    <MenuItem value={"Rabu"}>Rabu</MenuItem>
                                    <MenuItem value={"Kamis"}>Kamis</MenuItem>
                                    <MenuItem value={"Jumat"}>Jumat</MenuItem>
                                    <MenuItem value={"Sabtu"}>Sabtu</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                clearable
                                required
                                value={tanggal}
                                inputVariant="outlined"
                                label="Tanggal"
                                placeholder="10/10/2018"
                                onChange={handleChangeTanggal}
                                minDate={new Date()}
                                format="MM/dd/yyyy"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CurrencyTextField
                                label="Total"
                                variant="outlined"
                                currencySymbol="Rp"
                                //minimumValue="0"
                                outputFormat="string"
                                decimalCharacter="."
                                digitGroupSeparator=","
                                onChange={handleChangeTotal}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" required className={classes.formControl}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={status}
                                    onChange={handleChangeStatus}
                                >
                                    <MenuItem value={"Pengeluaran Rutin Pekanan"}>Pengeluaran Rutin Pekanan</MenuItem>
                                    <MenuItem value={"Pengeluaran Rutin Bulanan"}>Pengeluaran Rutin Bulanan</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Deskripsi"
                                variant="outlined"
                                fullWidth
                                onChange={handleChangeDeskripsi}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={handleButtonSubmit} fullWidth>
                                Input
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" fullWidth>
                                Reset
                            </Button>
                        </Grid>
                        {
                            success ?
                                <SuccessSnackbar /> :
                                error ?
                                    <FailedSnackbar /> : <div />
                        }
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}