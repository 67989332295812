
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { NavLink } from 'react-router-dom';

export const mainListItems = (
    <div>
        <ListItem button component={NavLink} to={'/dashboard'} activeStyle={{ backgroundColor: "#ffffff0f", borderRight: "2px solid #3eb554" }} style={{ paddingLeft: '18px', paddingTop: '15px', paddingBottom: '15px' }}>
            <ListItemIcon>
                <DashboardIcon style={{ color: '#3eb554' }} />
            </ListItemIcon>
        </ListItem>
        <ListItem button component={NavLink} to={'/penceramah'} activeStyle={{ backgroundColor: "#ffffff1f", borderRight: "2px solid #3eb554" }} style={{ paddingLeft: '18px', paddingTop: '15px', paddingBottom: '15px' }}>
            <ListItemIcon>
                <PersonIcon style={{ color: '#3eb554' }} />
            </ListItemIcon>
        </ListItem>
        <ListItem button component={NavLink} to={'/pemasukan'} activeStyle={{ backgroundColor: "#ffffff1f", borderRight: "2px solid #3eb554" }} style={{ paddingLeft: '18px', paddingTop: '15px', paddingBottom: '15px' }}>
            <ListItemIcon>
                <AddBoxIcon style={{ color: '#3eb554' }} />
            </ListItemIcon>
        </ListItem>
        <ListItem button component={NavLink} to={'/pengeluaran'} activeStyle={{ backgroundColor: "#ffffff1f", borderRight: "2px solid #3eb554" }} style={{ paddingLeft: '18px', paddingTop: '15px', paddingBottom: '15px' }}>
            <ListItemIcon>
                <IndeterminateCheckBoxIcon style={{ color: '#3eb554' }} />
            </ListItemIcon>
        </ListItem>
        <ListItem button component={NavLink} to={'/tambahan'} activeStyle={{ backgroundColor: "#ffffff1f", borderRight: "2px solid #3eb554" }} style={{ paddingLeft: '18px', paddingTop: '15px', paddingBottom: '15px' }}>
            <ListItemIcon>
                <AccountBalanceWalletIcon style={{ color: '#3eb554' }} />
            </ListItemIcon>
        </ListItem>
        <ListItem button component={NavLink} to={'/majelis'} activeStyle={{ backgroundColor: "#ffffff1f", borderRight: "2px solid #3eb554" }} style={{ paddingLeft: '18px', paddingTop: '15px', paddingBottom: '15px' }}>
            <ListItemIcon>
                <PeopleIcon style={{ color: '#3eb554' }} />
            </ListItemIcon>
        </ListItem>
    </div>
);