import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios'
import Format from '../../utils/Format'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        margin: theme.spacing(1),
    },
    button: {
        textAlign: 'right',
    },
    bold: {
        fontWeight: 'bold'
    },
    card: {
        marginTop: theme.spacing(6),
        borderRadius: '13px',
        transition: '0.3s',
        width: '100%',
        display: 'grid',
        overflow: 'initial',
        background: '#ffffff',
        minHeight: 200
    },
    content: {
        textAlign: 'left',
        overflowX: 'auto',
        marginTop: 15
    },
    header: {
        background: 'linear-gradient(to right, rgb(59, 138, 87), #3eb554)',
    },
    formControl: {
        width: '100%'
    },
}));

export default function CenteredGrid() {
    const classes = useStyles();
    const cardHeaderStyles = useContainedCardHeaderStyles();
    const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
    const cardHeaderShadowStyles = useFadedShadowStyles();
    const [data, setData] = useState();

    useEffect(() => {
        let newData;
        axios.get('https://lit-sierra-81196.herokuapp.com/api/majelis/latest')
            .then(res => {
                newData = res ? res.data.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal) })) : []
                setData(newData)
            })
            .catch(err => {
                setData([])
            })
    }, [])

    return (
        <div className={classes.root}>
            <Card className={cx(classes.card, cardShadowStyles.root)}>
                <CardHeader
                    className={cx(cardHeaderShadowStyles.root, classes.header)}
                    classes={cardHeaderStyles}
                    title={'LIST INFO MAJELIS'}
                    subheader={'Detail List Informasi Majelis.'}
                />
                <CardContent className={classes.content}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Hari</TableCell>
                                <TableCell>Tanggal</TableCell>
                                <TableCell>Pemateri</TableCell>
                                <TableCell>Materi</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data ? data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.hari}</TableCell>
                                    <TableCell>{row.tanggal}</TableCell>
                                    <TableCell>{row.pemateri}</TableCell>
                                    <TableCell>{row.materi}</TableCell>
                                </TableRow>
                            )) : []}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    );
}