import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// 
import { useHistory } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Nurul Iman
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: '#3eb554'
    },
    error: {
        marginTop: 20,
        textAlign: 'center',
        color: 'red'
    },
    logo: {
        width: '300px'
    }
}));

export default function SignIn() {
    const classes = useStyles();
    let history = useHistory();
    let adminCredential = {
        username: "admin",
        password: "12345"
    }
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(false);

    const handleToDashboard = event => {
        event.preventDefault()
        if (username === adminCredential.username && password === adminCredential.password) {
            history.push('/dashboard')
        } else {
            setError(true)
        }
    }

    const handleChangeUsername = event => {
        setUsername(event.target.value)
    }

    const handleChangePassword = event => {
        setPassword(event.target.value)
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={require("../assets/img/mosque.png")} alt="NurulIman Logo" className={classes.logo} />
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Username"
                        onChange={handleChangeUsername}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        onChange={handleChangePassword}
                    />
                    {
                        error ?
                            <Typography variant="h6" gutterBottom style={{ marginTop: 20, textAlign: 'center', color: 'red' }}>
                                Username dan Password and Salah!
                            </Typography>
                            :
                            <div />
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleToDashboard}
                        className={classes.submit}
                    >
                        Login
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container >
    );
}