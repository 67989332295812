import React from 'react';
import './App.css';
import SignIn from './views/SignIn';
import Dashboard from './views/Dashboard';
import Monitor from './views/Monitor';
import Penceramah from './views/Penceramah/Index';
import Pemasukan from './views/Pemasukan/Index';
import Pengeluaran from './views/Pengeluaran/Index';
import Tambahan from './views/Tambahan/Index';
import Majelis from './views/Majelis/Index';
import { BrowserRouter as Router,Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route path="/jumat" component={Monitor} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/penceramah" component={Penceramah} />
          <Route path='/pemasukan' component={Pemasukan} />
          <Route path='/pengeluaran' component={Pengeluaran} />
          <Route path='/tambahan' component={Tambahan} />
          <Route path='/majelis' component={Majelis} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default App;
