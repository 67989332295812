import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Format from '../utils/Format'


export default function Deposits() {
    const [data, setData] = useState();

    useEffect(() => {
        let newData;
        axios.get('https://lit-sierra-81196.herokuapp.com/api/penceramah/latest')
            .then(res => {
                newData = res ? res.data.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal) })) : []
                setData(newData[0])
            })
            .catch(err => {
                setData([])
            })
    }, [])

    return (
        <Fragment>
            <Typography component="p" variant="h6" style={{ color: '#fff', marginBottom: 16, textAlign: 'center' }}>
                CERAMAH JUMAT
            </Typography>
            <Typography component="p" variant="h5" style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>
                {data ? data.khatib : ''}
            </Typography>
            <div style={{ display: 'flex', alignSelf: 'center' }}>
                <Typography color="textSecondary" variant="overline" style={{ fontWeight: 'bold', color: '#fff', fontSize: '13px', alignSelf: 'center' }}>
                    {data ? Format.DayInWeek(data.tanggal) : '-'}
                </Typography>
                <Typography color="textSecondary" variant="overline" style={{ color: '#fff', fontSize: '11px', alignSelf: 'center' }}>
                    / {data ? Format.DateOnly(data.tanggal) : '-'}
                </Typography>
            </div>
            <div style={{ marginTop: 10 }}>
                <div>
                    <Typography color="textSecondary" variant="overline" style={{ color: '#fff', paddingLeft: 10 }}>
                        Imam :
                    </Typography>
                </div>
                <div>
                    <Typography color="textSecondary" variant="subtitle1" style={{ fontWeight: 'bold', color: '#fff', paddingLeft: 10 }}>
                        {data ? data.imam : ''}
                    </Typography>
                </div>
            </div>
            <div style={{ marginTop: 10 }}>
                <div>
                    <Typography color="textSecondary" variant="overline" style={{ color: '#fff', paddingLeft: 10 }}>
                        Muadzin :
                    </Typography>
                </div>
                <div>
                    <Typography color="textSecondary" variant="subtitle1" style={{ fontWeight: 'bold', color: '#fff', paddingLeft: 10 }}>
                        {data ? data.muadzin : ''}
                    </Typography>
                </div>
            </div>
        </Fragment>
    );
}