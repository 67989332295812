import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import Format from '../utils/Format'

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    title: {
        marginBottom: 16,
        fontWeight: 'bold'
    }
}));

export default function Orders() {
    const classes = useStyles();
    const [data, setData] = useState();

    useEffect(() => {
        let newData;
        axios.get('https://lit-sierra-81196.herokuapp.com/api/tambahan/latest')
            .then(res => {
                newData = res ? res.data.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal), total: parseFloat(x.total.replace(/,/g, '')) })) : []
                setData(newData)
            })
            .catch(err => {
                setData([])
            })
    }, [])

    return (
        <Fragment>
            <Typography component="p" variant="h5" className={classes.title}>
                LIST INFAQ
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Hari</TableCell>
                        <TableCell>Tanggal</TableCell>
                        <TableCell>Nama</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data ? data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.hari}</TableCell>
                            <TableCell>{row.tanggal}</TableCell>
                            <TableCell>{row.nama}</TableCell>
                            <TableCell align="right">Rp.{Format.FormatIDR(row.total)}</TableCell>
                        </TableRow>
                    )) : []}
                </TableBody>
            </Table>
            <div className={classes.seeMore} />
        </Fragment>
    );
}