import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Format from '../../utils/Format';
import Excel from "exceljs"
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(4),
        },
    },
}));

export default function ContainedButtons() {
    const classes = useStyles();
    const [data, setData] = useState();

    useEffect(() => {
        let newData;
        axios.get('https://lit-sierra-81196.herokuapp.com/api/pengeluaran')
            .then(res => {
                newData = res ? res.data.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal) })) : []
                setData(newData)
            })
            .catch(err => {
                setData([])
            })
    }, [])

    const generateReport = async () => {
        const wb = new Excel.Workbook()
        const ws = wb.addWorksheet()

        // Header
        ws.mergeCells('A2:B2')
        ws.getCell('A2').value = "Mesjid Nurul Iman - List Pengeluaran"

        // Header Style
        ws.getRow(2).height = 40
        ws.getCell('A2').font = { size: 25, bold: true }

        // Company Information
        ws.getCell('A4').value = 'Generate By:'
        ws.getCell('B4').value = 'Admin'
        ws.getCell('A5').value = 'Generate Date:'
        ws.getCell('B5').value = Format.FullDate(new Date())

        // Company Information Style
        ws.getRow(4).height = 25
        ws.getCell('A4').font = { size: 12 };
        ws.getCell('B4').font = { size: 14, bold: true }
        ws.getRow(5).height = 25
        ws.getCell('A5').font = { size: 12 };
        ws.getCell('B5').font = { size: 14, bold: true }
        ws.getRow(6).height = 25

        // Column Style
        ws.getRow(8).values = ['Id', 'Hari', 'Tanggal', 'Total', 'Status', 'Deskripsi']
        ws.getRow(8).height = 25
        ws.getRow(8).font = { size: 16, bold: true };

        // Add column headers
        ws.columns = [
            { key: 'id', width: 20 },
            { key: 'hari', width: 50 },
            { key: 'tanggal', width: 50 },
            { key: 'total', width: 50 },
            { key: 'status', width: 50 },
            { key: 'deskripsi', width: 60 }
        ];

        // Add column data
        data.forEach((x, i) => {
            const xs = ws.addRow({
                id: i + 1,
                hari: x.hari,
                tanggal: x.tanggal,
                total: x.total,
                status: x.status,
                deskripsi: x.deskripsi
            })
            xs.height = 18
        })

        // Table alignment center 
        ws.eachRow((row, rowNum) => {
            row.eachCell((Cell, cellNum) => {
                Cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center'
                }
            })
        })

        const buf = await wb.xlsx.writeBuffer()

        FileSaver(new Blob([buf]), `Mesjid Nurul Iman - List Pengeluaran - ${Format.FullDate(new Date())}.xlsx`)
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={generateReport} fullWidth>
                        Generate Report
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}