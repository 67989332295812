import React, { useState } from 'react';
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Format from '../../utils/Format';
import axios from 'axios';
import SuccessSnackbar from '../../components/Snackbar/Success';
import FailedSnackbar from '../../components/Snackbar/Failed';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        margin: theme.spacing(1),
    },
    button: {
        textAlign: 'right',
    },
    bold: {
        fontWeight: 'bold'
    },
    card: {
        marginTop: theme.spacing(7),
        borderRadius: theme.spacing(0.5),
        transition: '0.3s',
        width: '100%',
        display: 'grid',
        overflow: 'initial',
        background: '#ffffff',
        minHeight: 600
    },
    content: {
        textAlign: 'left',
        overflowX: 'auto',
    },
    header: {
        background: 'linear-gradient(to right, rgb(59, 138, 87), #3eb554)',
    }
}));

export default function CenteredGrid() {
    const classes = useStyles();
    const cardHeaderStyles = useContainedCardHeaderStyles();
    const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
    const cardHeaderShadowStyles = useFadedShadowStyles();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [body, setBody] = useState({
        hari: 'Jumat',
        tanggal: undefined,
        khatib: '',
        nomorTelfonKhatib: '',
        naibKhatib: '',
        imam: '',
        naibImam: '',
        muadzin: '',
    })

    const handleChangeDate = event => {
        setBody({
            ...body,
            tanggal: Format.FullDate(event)
        })
    }

    const handleChangeKhatib = event => {
        setBody({
            ...body,
            khatib: event.target.value
        })
    }

    const handleChangeNomorTelfonKhatib = event => {
        setBody({
            ...body,
            nomorTelfonKhatib: event.target.value
        })
    }

    const handleChangeNaibKhatib = event => {
        setBody({
            ...body,
            naibKhatib: event.target.value
        })
    }
    const handleChangeImam = event => {
        setBody({
            ...body,
            imam: event.target.value
        })
    }
    const handleChangeNaibImam = event => {
        setBody({
            ...body,
            naibImam: event.target.value
        })
    }
    const handleChangeMuadzin = event => {
        setBody({
            ...body,
            muadzin: event.target.value
        })
    }

    const handleButtonSubmit = event => {
        event.preventDefault();
        axios.post('https://lit-sierra-81196.herokuapp.com/api/penceramah', body)
            .then(res => {
                setSuccess(true)
                window.location.reload();
            })
            .catch(err => {
                setError(true)
            })
    }

    const handleButtonReset = () => {
        setBody({
            ...body,
            hari: 'Jumat',
            tanggal: undefined,
            khatib: '',
            nomorTelfonKhatib: '',
            naibKhatib: '',
            imam: '',
            naibImam: '',
            muadzin: ''
        })
    }

    return (
        <div className={classes.root}>
            <Card className={cx(classes.card, cardShadowStyles.root)}>
                <CardHeader
                    className={cx(cardHeaderShadowStyles.root, classes.header)}
                    classes={cardHeaderStyles}
                    title={'Input Penceramah'}
                    subheader={'Masukan Detail Penceramah Jumat.'}
                />
                <CardContent className={classes.content}>
                    <Grid container spacing={3} style={{ marginTop: 8 }}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Hari"
                                disabled
                                defaultValue="Jumat"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                clearable
                                required
                                value={body.tanggal}
                                inputVariant="outlined"
                                label="Tanggal"
                                onChange={handleChangeDate}
                                minDate={new Date()}
                                format="MM/dd/yyyy"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Khatib"
                                variant="outlined"
                                value={body.khatib}
                                fullWidth
                                onChange={handleChangeKhatib}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Nomor Telfon Khatib"
                                variant="outlined"
                                value={body.nomorTelfonKhatib}
                                type="number"
                                fullWidth
                                onChange={handleChangeNomorTelfonKhatib}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Naib Khatib"
                                variant="outlined"
                                value={body.naibKhatib}
                                fullWidth
                                onChange={handleChangeNaibKhatib}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Imam"
                                variant="outlined"
                                value={body.imam}
                                fullWidth
                                onChange={handleChangeImam}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Naib Imam"
                                variant="outlined"
                                value={body.naibImam}
                                fullWidth
                                onChange={handleChangeNaibImam}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Muadzin"
                                variant="outlined"
                                value={body.muadzin}
                                fullWidth
                                onChange={handleChangeMuadzin}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={handleButtonSubmit} fullWidth>
                                Input
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" onClick={handleButtonReset} fullWidth>
                                Reset
                            </Button>
                        </Grid>
                        {
                            success ?
                                <SuccessSnackbar /> :
                                error ?
                                    <FailedSnackbar /> : <div />
                        }
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}