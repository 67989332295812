import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Format from '../utils/Format'

export default function Deposits() {
    const [pemasukanData, setPemasukanData] = useState();
    const [lastTanggalPemasukan, setLastTanggalPemasukan] = useState();
    const [pengeluaranData, setPengeluaranData] = useState();
    const [infaqData, setInfaqData] = useState();

    useEffect(() => {
        let pemasukanData, filterCalcPemasukanData, lastPemasukanData, pengeluaranData, filterCalcPengeluaranData, infaqData, filterCalcInfaqData;

        let apiGetTotalPemasukan = "https://lit-sierra-81196.herokuapp.com/api/pemasukan";
        let apiGetTotalPengeluaran = "https://lit-sierra-81196.herokuapp.com/api/pengeluaran";
        let apiGetTotalInfaq = "https://lit-sierra-81196.herokuapp.com/api/tambahan";

        const getRequestPemasukan = axios.get(apiGetTotalPemasukan);
        const getRequestPengeluaran = axios.get(apiGetTotalPengeluaran);
        const getRequestInfaq = axios.get(apiGetTotalInfaq);

        axios.all([getRequestPemasukan, getRequestPengeluaran, getRequestInfaq])
            .then(axios.spread((...responses) => {
                const totalPemasukan = responses ? responses[0].data : []
                const totalPengeluaran = responses ? responses[1].data : []
                const totalInfaq = responses ? responses[2].data : []

                // Total Pemasukan
                pemasukanData = totalPemasukan ? totalPemasukan.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal), total: parseFloat(x.total.replace(/,/g, '')) })) : []
                filterCalcPemasukanData = totalPemasukan ? pemasukanData
                    .map(x => x.total)
                    .reduce((prev, curr) => prev + curr, 0) : []
                lastPemasukanData = totalPemasukan !== [] ? totalPemasukan.pop() : []
                setLastTanggalPemasukan(lastPemasukanData)
                setPemasukanData(filterCalcPemasukanData !== [] ? filterCalcPemasukanData : 0)

                // Total Pengeluaran
                pengeluaranData = totalPengeluaran ? totalPengeluaran.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal), total: parseFloat(x.total.replace(/,/g, '')) })) : []
                filterCalcPengeluaranData = totalPengeluaran ? pengeluaranData
                    .map(x => x.total)
                    .reduce((prev, curr) => prev + curr, 0) : []
                setPengeluaranData(filterCalcPengeluaranData !== [] ? filterCalcPengeluaranData : 0)

                // Total Infaq
                infaqData = totalInfaq ? totalInfaq.map((x, i) => ({ ...x, tanggal: Format.FullDate(x.tanggal), total: parseFloat(x.total.replace(/,/g, '')) })) : []
                filterCalcInfaqData = totalInfaq ? infaqData
                    .map(x => x.total)
                    .reduce((prev, curr) => prev + curr, 0) : []
                setInfaqData(filterCalcInfaqData !== [] ? filterCalcInfaqData : 0)
            }))
            .catch(errors => {
                setPemasukanData([])
                setPengeluaranData([])
                setInfaqData([])
            })
    }, [])

    return (
        <Fragment>
            <Typography component="p" variant="h6" style={{ color: '#fff', marginBottom: 16, textAlign: 'center' }}>
                SALDO KAS
            </Typography>
            <Typography component="p" variant="h5" style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>
                Rp.{pemasukanData ? Format.FormatIDR((pemasukanData + infaqData) - pengeluaranData) : 0}
            </Typography>
            <div style={{ display: 'flex', alignSelf: 'center' }}>
                <Typography color="textSecondary" variant="overline" style={{ fontWeight: 'bold', color: '#fff', fontSize: '13px', alignSelf: 'center' }}>
                    {lastTanggalPemasukan ? Format.DayInWeek(lastTanggalPemasukan.tanggal) : '-'}
                </Typography>
                <Typography color="textSecondary" variant="overline" style={{ color: '#fff', fontSize: '11px', alignSelf: 'center' }}>
                    / {lastTanggalPemasukan ? Format.DateOnly(lastTanggalPemasukan.tanggal) : '-'}
                </Typography>
            </div>
            <div style={{ marginTop: 10 }}>
                <div>
                    <Typography color="textSecondary" variant="overline" style={{ color: '#fff', paddingLeft: 10 }}>
                        Total Pemasukan :
                    </Typography>
                </div>
                <div>
                    <Typography color="textSecondary" variant="subtitle1" style={{ fontWeight: 'bold', color: '#fff', paddingLeft: 10 }}>
                        Rp.{pemasukanData ? Format.FormatIDR(pemasukanData + infaqData) : 0}
                    </Typography>
                </div>
            </div>
            <div style={{ marginTop: 10 }}>
                <div>
                    <Typography color="textSecondary" variant="overline" style={{ color: '#fff', paddingLeft: 10 }}>
                        Total Pengeluaran :
                    </Typography>
                </div>
                <div>
                    <Typography color="textSecondary" variant="subtitle1" style={{ fontWeight: 'bold', color: '#fff', paddingLeft: 10 }}>
                        Rp.{pengeluaranData ? Format.FormatIDR(pengeluaranData) : 0}
                    </Typography>
                </div>
            </div>
        </Fragment >
    );
}